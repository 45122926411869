import { useEffect, useState } from 'react';
import './App.css';
import image from './shulzoomed.jpeg';

const initialValue = 9800;

const peopleArray = [
  {
    name: 'Moshe Danziger',
    amountRaised: 23138,
    goal: '25,000',
    link: 'https://square.link/u/XdT8FmW6',
  },
  {
    name: 'Naftali Rozsansky',
    amountRaised: 23018,
    goal: '25,000',
    link: 'https://square.link/u/EKCWZiY3',
  },
  {
    name: 'Dovid Goldstein',
    amountRaised: 10180,
    goal: '20,000',
    link: 'https://square.link/u/sXthrXyl',
  },
  {
    name: 'Yossi Cyperstein',
    amountRaised: 2800,
    goal: '15,000',
    link: 'https://square.link/u/xWXPW8nZ',
  },
  {
    name: 'Eliezer Gersten',
    amountRaised: 16002,
    goal: '20,000',
    link: 'https://square.link/u/3qZwKOiH',
  },
  {
    name: 'Chaim Handler',
    amountRaised: 6428,
    goal: '10,000',
    link: 'https://square.link/u/pN1OOr5s',
  },
  {
    name: 'Menachem Tepper',
    amountRaised: 30970,
    goal: '15,000',
    link: 'https://square.link/u/UzYh5Wz6',
  },
  {
    name: 'Eli Klein',
    amountRaised: 5010,
    goal: '10,000',
    link: 'https://square.link/u/a7GK8JBB',
  },
  {
    name: 'Chesky Spigelman',
    amountRaised: 7519,
    goal: '10,000',
    link: 'https://square.link/u/8w44O8Ju',
  },
  {
    name: 'Yaakov Drillick',
    amountRaised: 15250,
    goal: '10,000',
    link: 'https://square.link/u/fsVfYo0k',
  },
  {
    name: 'Eli Berl',
    amountRaised: 2500,
    goal: '5,000',
    link: 'https://square.link/u/jsdUeeak',
  },
  {
    name: 'Yitzi Schonfeld',
    amountRaised: 1340,
    goal: '7,200',
    link: 'https://square.link/u/ICc9YmCN',
  },
  {
    name: 'Yehuda Neydavoud',
    amountRaised: 2000,
    goal: '10,000',
    link: 'https://square.link/u/Ft0Bh24H',
  },
  {
    name: 'Yitzy Weiss',
    amountRaised: 3960,
    goal: '15,000',
    link: 'https://square.link/u/H332uoaW',
  },
  {
    name: 'Shuey Chapler',
    amountRaised: 7752,
    goal: '8,000',
    link: 'https://square.link/u/sGGQAvlJ',
  },
  {
    name: 'Moshe Greenspan',
    amountRaised: 7089,
    goal: '15,000',
    link: 'https://square.link/u/qSnARmPW',
  },
  {
    name: 'Shlomo Kozlov',
    amountRaised: 3928,
    goal: '5,000',
    link: 'https://square.link/u/0pjPReBp',
  },
  {
    name: 'Meli Leibiker',
    amountRaised: 2901,
    goal: '5,000',
    link: 'https://square.link/u/aaqo2ONY',
  },
  {
    name: 'Shmuel Kagan',
    amountRaised: 0,
    goal: '5,000',
    link: 'https://square.link/u/GJ2d7a6t',
  },
];

let total = peopleArray.reduce(
  (a, v) => (a = a + v.amountRaised),
  initialValue
);

function App() {
  return (
    <div className='App'>
      <div style={{ position: 'relative', maxWidth: 1920 }}>
        <div className='top-section' style={{ position: 'relative' }}>
          <img src={image} alt='' className='image' />
          <span className='text-image'>
            Future home of Bais Medrash of Bennetts Mills
          </span>
<span className='under-contract'>Under Contract!</span>
          <div
           className='right-side'
          >
            <div className='big-words'>
              <div className='title'>
                Goal <span className='amount'>$600,000</span>
              </div>
              <div className='title'>
                Total Amount Raised
                <span className='amount'> ${total.toLocaleString()}</span>
              </div>
            </div>
            <div
             style={{
              color: '#fff',
              padding: 16,
              fontSize: 20,
              borderRadius: 5,
              lineHeight: 2,
              // marginLeft: 40,
              backgroundColor: 'rgb(180, 180, 176)',
              position: 'relative',
              maxWidth:605,
              marginTop:12,
              textAlign:'center'
            }}
            className="about"
            >
              We are a small but rapidly growing community of Bnei Torah under
              the guidance of Rav Henoch Shachar Shlita. A unique opportunity has
              arisen which will allow us to move out of our garage shul and
              establish a makom torah for the greater Bennetts Mills area. We
              are currently under contract to purchase a new property and have
              limited time to raise the necessary funds. Please help us reach our
              goal!
            </div>
            <a
              href='https://square.link/u/J8RPwRAM'
              target='_blank'
              rel='noreferrer'
              style={{
                backgroundColor: '#77b28a',
                width: 305,
                height: 48,
                borderRadius: 5,
                color: '#fff',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              Donate Now
            </a>
          </div>
        </div>
        <div className='outer-cards'>
          {peopleArray.map((pa) => {
            return (
              <div className='cards'>
                <div className='person-name'>{pa.name}</div>
                <div className='goal-text'>
                  Goal <span style={{ color: '#77b28a' }}>${pa.goal}</span>
                </div>

                <div className='goal-text'>
                  Amount Raised{' '}
                  <div style={{ color: '#77b28a' }}>
                    ${pa.amountRaised.toLocaleString()}
                  </div>
                </div>
                <a
                  href={pa.link}
                  target='_blank'
                  rel='noreferrer'
                  className='donate-button'
                >
                  Donate to this page
                </a>
              </div>
            );
          })}
        </div>
        <div className='bottom-section'>
          <div
            style={{
              color: '#fff',
              padding: 40,
              maxWidth: 800,
              fontSize: 20,
              borderRadius: 5,
              lineHeight: 2,
              // margin: '0 auto',
              backgroundColor: 'rgb(180, 180, 176)',
              position: 'relative',
              marginTop: 60,
              textAlign: 'left',
            }}
          >
            <div className='section-title'>About the Community</div>
            <div style={{ paddingTop: 24 }}>
              Bais Medrash of Bennetts Mills is a new community project which
              was founded to accommodate the fast growing community of dedicated
              בני תורה who reside in the Bennets Mills area in Jackson. The
              Kehila encompasses Ash, Teakwood, Mimosa, Arboretum and Kimberly
              streets as well as neighboring areas.
              <div style={{ paddingTop: 24 }}>
                With tremendous סיעטא דשמיא and the tireless efforts of our
                askanim, we were able to secure a beautiful two acre plus
                property, located off of Bennetts Mills Road. The Kehila is
                currently under contract for this property which includes a
                ranch style house. After minor renovations, this structure can
                serve as a starter home for our shul. We have a long term vision
                of building a spacious Bais Medrash on this property, in
                compliance with local requirements and regulations.
              </div>
              <div style={{ paddingTop: 24 }}>
                Under the guidance and with the endorsement of Rav Henoch
                Shachar Shlita, we have embarked on a six hundred thousand
                dollar fund raising campaign to enable us to finalize this
                purchase.
              </div>
              <div style={{ paddingTop: 24 }}>
                With great joy, we anticipate that Bais Medrash of Bennets Mills
                will service the growing community's need for a בית הכנסת, a
                place for תפילה, In addition, it will serve as a בית מדרש and
                מקום תורה as many of the בני תורה residing in the area have
                expressed interest in establishing a night seder in the new
                shul. Your generous contribution will not only give you the זכות
                of enhancing the תפילות of our community but it will also give
                you the additional זכות of החזקת התורה.
              </div>
              <div style={{ paddingTop: 24 }}>
                May your partnership in building a מקום of תורה and תפילה in our
                new מקדש מעט be an eternal זכות for you and your families and
                may you be granted much נחת and ברכה והצלחה.
              </div>
            </div>
          </div>
          <div className='info-section'>
            <div
              style={{
                color: '#fff',
                padding: 30,
                fontSize: 20,
                borderRadius: 5,
                lineHeight: 2,
                marginLeft: 40,
                backgroundColor: 'rgb(180, 180, 176)',
                position: 'relative',
                marginTop: 60,
                textAlign: 'left',
              }}
            >
              <div className='section-title'>Donate</div>
              <a
                href='https://square.link/u/J8RPwRAM'
                target='_blank'
                rel='noreferrer'
              >
                https://square.link/u/J8RPwRAM
              </a>
            </div>

            <div
              style={{
                color: '#fff',
                padding: 40,
                fontSize: 20,
                borderRadius: 5,
                lineHeight: 2,
                marginLeft: 40,
                backgroundColor: 'rgb(180, 180, 176)',
                position: 'relative',
                marginTop: 60,
                textAlign: 'left',
              }}
            >
              <div className='section-title'>Information</div>
              <div>Legal Name:</div>
              <div>Teakwood Shul</div>
              <div>33 Teakwood Drive</div>
              <div>Jackson, NJ 08527</div>
              <div>Tax Id: 87-1384114</div>

              <div style={{ paddingTop: 24 }}>
                Checks can be mailed to this address
              </div>
            </div>
            <div
              style={{
                color: '#fff',
                padding: 40,
                fontSize: 20,
                borderRadius: 5,
                lineHeight: 2,
                marginLeft: 40,
                backgroundColor: 'rgb(180, 180, 176)',
                position: 'relative',
                marginTop: 60,
                textAlign: 'left',
              }}
            >
              <div className='section-title'>Contact Us</div>
              <div style={{ color: '#fff', lineHeight: 1.2 }}>
                For dedication opportunities, or more information email:
              </div>
              <a href='mailto:teakwoodshul@gmail.com'>
                bmbennettsmills@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
